import { memo, useCallback, useMemo, useState } from "react";
import localFont from "next/font/local";
import { Box, Flex } from "@atoms/Flex";
import { useTheme } from "styled-components/native";
import { useMedia } from "@hooks/useMedia";
import { Text } from "@atoms/Text";
import { Image } from "@atoms/Image";
import MovingWidget from "./BottomSlider";
import { useRouting } from "expo-next-react-navigation";
import Modal from "@organisms/Modal";
import { Referrals } from "@screens/ETHCC/Derby/Referrals";
import useAnalytics from "@hooks/useAnalytics";
import { HOME_EVENT_NAMES } from "constants/events";

//const ppMori = localFont({ src: "../../fonts/PPMori-SemiBold.ttf" });

export const LaunchScreen = () => {
    const theme = useTheme();
    const media = useMedia();
    const { navigate } = useRouting();
    const [isReferralModalVisible, setReferralModalVisible] = useState(false);
    const { trackEvent } = useAnalytics()

    const toggleReferralModal = useCallback(() => {
        setReferralModalVisible(!isReferralModalVisible);
    }, [isReferralModalVisible]);

    return (
        <Box
            align="center"
            style={{
                height:
                    media.isMobile || media.isTablet
                        ? "calc(100vh)"
                        : "calc(100vh - 90px)",
            }}
            onScroll={() => console.log("yoyoyoyo")}
        >
            <Box
                align="center"
                style={{
                    maxWidth: 1680,
                    width: "100%",
                    paddingHorizontal: theme.spacing.bigger,
                    paddingTop: 0,
                }}
            >
                <Box
                    gap={theme.spacing.huge}
                    align="center"
                    style={{
                        width: "100%",
                        paddingTop: theme.spacing.huge,
                    }}
                >
                    <Box
                        style={{
                            position: "absolute",
                            top: "40%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "100%", // Adjusted to make the gradient spread more
                            height: "80%", // Adjusted to make the gradient spread more

                            background:
                                "radial-gradient(55% 55% at 52.69% 48.01%, rgba(232, 83, 24, 0.15) 0%, rgba(130, 47, 13, 0.00) 100%)", // Increased the size of the gradient
                            // Ensure it stays behind the cards
                            borderRadius: "24px", // Adjust if necessary
                        }}
                    />
                    <Box
                        align={media.isMobile ? "start" : "center"}
                        gap={theme.spacing.bigger}
                    >
                        <Text huge smaller primary usePPSemiBold>
                            MACRO MADE TRADABLE
                        </Text>
                        <Text
                            semiBold
                            size={
                                media.isMobile
                                    ? theme.spacing.huge
                                    : theme.spacing.huge
                            }
                            color={theme.color.white}
                            usePPSemiBold
                        >
                            Trade commodities, forex & indices onchain
                        </Text>
                    </Box>

                    <Flex
                        direction={media.isTabletOrMobile ? "column" : "row"}
                        gap={theme.spacing.bigger}
                    >
                        <Box
                            style={{
                                maxWidth: media.isTabletOrMobile ? "auto" : 266,
                                padding: theme.spacing.biggest,
                                borderRadius: "24px",
                                border: "1px solid rgba(208, 219, 218, 0.03)",
                                background:
                                    "linear-gradient(0deg, rgba(208, 219, 218, 0.05) -22.28%, rgba(208, 219, 218, 0.00) 196.89%)",
                                boxShadow:
                                    "0px 2px 8px 0px rgba(7, 6, 6, 0.20), 0px 4px 50px 0px rgba(7, 6, 6, 0.20)",
                            }}
                            gap={theme.spacing.huge}
                        >
                            <Box>
                                <Box
                                    style={{
                                        display: "flex",
                                        padding: "12px",
                                        alignItems: "center",
                                        width: "fit-content",
                                        borderRadius: 1000,
                                        border: "1px solid #565656",
                                        background:
                                            "linear-gradient(180deg, rgba(208, 219, 218, 0.04) 0%, rgba(208, 219, 218, 0.00) 100%), rgba(208, 219, 218, 0.08)",
                                    }}
                                >
                                    <Image
                                        source={`/assets/tradeIcon.svg`}
                                        priority
                                        width={24}
                                        height={24}
                                        alt={`/announcement-tradeIcon`}
                                    />
                                </Box>
                            </Box>
                            <Box gap={theme.spacing.bigger}>
                                <Text bigger semiBold usePPSemiBold>
                                    Trade
                                </Text>
                                <Text
                                    small
                                    style={{
                                        color: "rgba(208, 219, 218, 0.80)",
                                    }}
                                    usePPSemiBold
                                >
                                    Start trading with as little as $10 and just
                                    a crypto wallet.
                                </Text>
                            </Box>
                            <Box
                                style={{
                                    background: "rgba(208, 219, 218, 0.08)",
                                    borderRadius: 8,
                                    padding: 10,
                                    cursor: "pointer",
                                    zIndex: 2,
                                }}
                                align="center"
                                onClick={() => {
                                    trackEvent(HOME_EVENT_NAMES.TRADE_CLICK)
                                    navigate({
                                        routeName: "/trade",
                                        params: {
                                            from: "CL",
                                            to: "USD",
                                        },
                                    })
                                }
                                }
                            >
                                <Text small semiBold usePPSemiBold>
                                    Trade on Ostium
                                </Text>
                            </Box>
                        </Box>

                        <Box
                            style={{
                                maxWidth: media.isTabletOrMobile ? "auto" : 266,
                                padding: theme.spacing.biggest,
                                borderRadius: "24px",
                                border: "1px solid rgba(208, 219, 218, 0.03)",
                                background:
                                    "linear-gradient(0deg, rgba(208, 219, 218, 0.05) -22.28%, rgba(208, 219, 218, 0.00) 196.89%)",
                                boxShadow:
                                    "0px 2px 8px 0px rgba(7, 6, 6, 0.20), 0px 4px 50px 0px rgba(7, 6, 6, 0.20)",
                            }}
                            gap={theme.spacing.huge}
                        >
                            <Box>
                                <Box
                                    style={{
                                        display: "flex",
                                        padding: "12px",
                                        alignItems: "center",
                                        width: "fit-content",
                                        borderRadius: 1000,
                                        border: "1px solid #565656",
                                        background:
                                            "linear-gradient(180deg, rgba(208, 219, 218, 0.04) 0%, rgba(208, 219, 218, 0.00) 100%), rgba(208, 219, 218, 0.08)",
                                    }}
                                >
                                    <Image
                                        source={`/assets/depositIcon.svg`}
                                        priority
                                        width={24}
                                        height={24}
                                        alt={`/announcement-tradeIcon`}
                                    />
                                </Box>
                            </Box>
                            <Box gap={theme.spacing.bigger}>
                                <Text bigger semiBold usePPSemiBold>
                                    Deposit
                                </Text>
                                <Text
                                    small
                                    style={{
                                        color: "rgba(208, 219, 218, 0.80)",
                                    }}
                                    usePPSemiBold
                                >
                                    Access boosted yields by locking up your
                                    USDC.
                                </Text>
                            </Box>
                            <Box
                                style={{
                                    background: "rgba(208, 219, 218, 0.08)",
                                    borderRadius: 8,
                                    padding: 10,
                                    cursor: "pointer",
                                }}
                                align="center"
                                onClick={() => {
                                    trackEvent(HOME_EVENT_NAMES.DEPOSIT_CLICK)
                                    navigate({
                                        routeName: "/vault",
                                    })
                                }
                                }
                            >
                                <Text small semiBold usePPSemiBold>
                                    Deposit USDC
                                </Text>
                            </Box>
                        </Box>

                        <Box
                            style={{
                                maxWidth: media.isTabletOrMobile ? "auto" : 266,
                                padding: theme.spacing.biggest,
                                borderRadius: "24px",
                                border: "1px solid rgba(208, 219, 218, 0.03)",
                                background:
                                    "linear-gradient(0deg, rgba(208, 219, 218, 0.05) -22.28%, rgba(208, 219, 218, 0.00) 196.89%)",
                                boxShadow:
                                    "0px 2px 8px 0px rgba(7, 6, 6, 0.20), 0px 4px 50px 0px rgba(7, 6, 6, 0.20)",
                            }}
                            gap={theme.spacing.huge}
                        >
                            <Box>
                                <Box
                                    style={{
                                        display: "flex",
                                        padding: "12px",
                                        alignItems: "center",
                                        width: "fit-content",
                                        borderRadius: 1000,
                                        border: "1px solid #565656",
                                        background:
                                            "linear-gradient(180deg, rgba(208, 219, 218, 0.04) 0%, rgba(208, 219, 218, 0.00) 100%), rgba(208, 219, 218, 0.08)",
                                    }}
                                >
                                    <Image
                                        source={`/assets/referIcon.svg`}
                                        priority
                                        width={24}
                                        height={24}
                                        alt={`/announcement-tradeIcon`}
                                    />
                                </Box>
                            </Box>
                            <Box gap={theme.spacing.bigger}>
                                <Text bigger semiBold usePPSemiBold>
                                    Refer
                                </Text>
                                <Text
                                    small
                                    style={{
                                        color: "rgba(208, 219, 218, 0.80)",
                                    }}
                                    usePPSemiBold
                                >
                                    Earn exclusive rewards with the Ostium
                                    Referral Program.
                                </Text>
                            </Box>
                            <Box
                                style={{
                                    background: "rgba(208, 219, 218, 0.08)",
                                    borderRadius: 8,
                                    padding: 10,
                                    cursor: "pointer",
                                }}
                                align="center"
                                onClick={() => {
                                    trackEvent(HOME_EVENT_NAMES.REFERRAL_CLICK)
                                    toggleReferralModal()
                                }}
                            >
                                <Text small semiBold usePPSemiBold>
                                    Get Referral Link
                                </Text>
                            </Box>
                        </Box>
                    </Flex>

                    {/* <Box
                        style={{
                            width: "965.398px",
                            height: "536.279px",
                            borderRadius: "8px",
                            border: "2px solid rgba(208, 219, 218, 0.05)",
                            backgroundSize: "contain",
                            //             background: `linear-gradient(180deg, rgba(7, 6, 6, 0) 0%, #070606 41.97%),
                            //  url('/assets/tradingUI.png') center center / contain no-repeat lightgray`, // Replace 'path-to-your-image' with the actual path
                        }}
                    /> */}

                    {media.isMobile ? null : (
                        <Box
                            style={{
                                position: "relative",
                                width: "900px",
                                height: 480,
                                overflow: "hidden",
                                border: "2px solid rgba(208, 219, 218, 0.05)",
                                borderRadius: "8px",
                                borderBottom: 0,
                                marginBottom: 150,
                            }}
                        >
                            {/* <Box
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    zIndex: 100,
                                    width: "100%",
                                    height: "10px", // Height of the orange border
                                    background: `
                                        linear-gradient(
                                            90deg,
                                            #070606 0%,
                                            #070606 100%,
                                            #D0DBDA 5%
                                        ),
                                        radial-gradient(
                                            circle,
                                            #FF5A19 30%,
                                            #070606 30%
                                        )`, // Orange color
                                    borderTopLeftRadius: "8px", // Match the border radius of the container
                                    borderTopRightRadius: "8px",
                                }}
                            /> */}
                            <Image
                                source={`/assets/tradingUI.jpg`}
                                priority
                                height={480}
                                width={900}
                                fill={true}
                                alt={`/announcement-tradingUI`}
                            />
                            <Box
                                style={{
                                    position: "absolute",
                                    bottom: "0",
                                    left: "0",
                                    width: "100%",
                                    height: "100%",
                                    background:
                                        "linear-gradient(180deg, rgba(0,0,0,0.08) 0%, rgba(0,0,0,0.8) 100%)",
                                    pointerEvents: "none",
                                }}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
            <Box
                gap={theme.spacing.biggest}
                style={{
                    paddingTop: media.isTabletOrMobile ? 40 : 0,
                    position: media.isMobile ? "relative" : "fixed",
                    bottom: 0,
                }}
            >
                <Box style={{ zIndex: 2 }}>
                    <Text primary smaller usePPSemiBold textAlign="center">
                        BACKED BY THE BEST
                    </Text>
                </Box>

                <MovingWidget />
            </Box>
            <Modal
                isVisible={isReferralModalVisible}
                onClose={toggleReferralModal}
                title={"Earn Referral Rewards"}
            >
                <Referrals onClose={toggleReferralModal} />
            </Modal>
        </Box>
    );
};
