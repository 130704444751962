import Marquee from "react-fast-marquee";
import { Image } from "@atoms/Image";
import { useMedia } from "@hooks/useMedia";
import React, { useEffect, useRef } from "react";

const MovingWidget = () => {
    const widgetRef = useRef(null);
    const media = useMedia();

    // useEffect(() => {
    //     const widget = widgetRef.current;

    //     const handleMouseOver = () => {
    //         if (widget) {
    //             //@ts-ignore
    //             widget.style.animationPlayState = "paused";
    //         }
    //     };
    //     const handleMouseOut = () => {
    //         if (widget) {
    //             //@ts-ignore
    //             widget.style.animationPlayState = "running";
    //         }
    //     };

    //     if (window.innerWidth > 1024) {
    //         //@ts-ignore
    //         widget.addEventListener("mouseover", handleMouseOver);
    //         //@ts-ignore
    //         widget.addEventListener("mouseout", handleMouseOut);
    //     }

    //     return () => {
    //         if (widget) {
    //             //@ts-ignore
    //             widget.removeEventListener("mouseover", handleMouseOver);
    //             //@ts-ignore
    //             widget.removeEventListener("mouseout", handleMouseOut);
    //         }
    //     };
    // }, []);

    return (
        <>
            <style>
                {`
                    @keyframes moveSlider {
                        0% {
                            transform: translateX(calc(100vw - 20px));
                        }
                        100% {
                            transform: translateX(-100%);
                        }
                    }
                    @media (max-width: 600px) {
                        .movingWidget {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            justify-content: center;
                            animation: none;
                            gap: 10px;
                        }
                    }
                    @media (min-width: 601px) and (max-width: 1024px) {
                        .movingWidget {
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            justify-content: center;
                            animation: none;
                            gap: 20px;
                        }
                    }
                    @media (min-width: 1025px) {
                        .movingWidget {
                            display: flex;
                            animation: moveSlider 15s linear infinite;
                        }
                    }
                `}
            </style>
            <div style={styles.widgetContainer}>
                <Marquee
                    style={{ gap: 4, width: "100%" }}
                    pauseOnClick={true}
                    speed={80}
                >
                    <div style={styles.slide}>
                        <Image
                            source={`/assets/arb.png`}
                            priority
                            width={155}
                            height={39}
                            alt={`Arbitrum Foundation`}
                        />
                    </div>
                    <div style={styles.slide}>
                        <Image
                            source={`/assets/balaji.png`}
                            priority
                            width={219}
                            height={28}
                            alt={`Balaji Srinivasan`}
                        />
                    </div>
                    <div style={styles.slide}>
                        <Image
                            source={`/assets/chainlink.png`}
                            priority
                            width={127}
                            height={32}
                            alt={`Gelato`}
                        />
                    </div>
                    <div style={styles.slide}>
                        <Image
                            source={`/assets/spencer_noon.png`}
                            priority
                            width={183}
                            height={28}
                            alt={`David Chen`}
                        />
                    </div>
                    <div style={styles.slide}>
                        <Image
                            source={`/assets/gc.png`}
                            priority
                            width={210}
                            height={33}
                            alt={`General Catalyst`}
                        />
                    </div>
                    <div style={styles.slide}>
                        <Image
                            source={`/assets/alliance_dark.png`}
                            priority
                            width={155}
                            height={17}
                            alt={`Jai Prasad`}
                        />
                    </div>
                    <div style={styles.slide}>
                        <Image
                            source={`/assets/lg.png`}
                            priority
                            width={176}
                            height={29}
                            alt={`LocalGlobe`}
                        />
                    </div>
                    <div style={styles.slide}>
                        <Image
                            source={`/assets/mck.png`}
                            priority
                            width={129}
                            height={28}
                            alt={`McKenna`}
                        />
                    </div>
                    <div style={styles.slide}>
                        <Image
                            source={`/assets/meltemD.png`}
                            priority
                            width={212}
                            height={28}
                            alt={`SIG Susquehanna`}
                        />
                    </div>
                    <div style={styles.slide}>
                        <Image
                            source={`/assets/sig.png`}
                            priority
                            width={117}
                            height={37}
                            alt={`SIG Susquehanna`}
                        />
                    </div>
                </Marquee>
            </div>
        </>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    widgetContainer: {
        zIndex: 1,
        position: "relative",
        width: "calc(100vw - 20px)",
        overflow: "hidden",
        backgroundColor: "black",
        paddingBottom: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    movingWidget: {
        display: "flex",
        animation: "moveSlider 300s linear infinite",
        gap: 60,
        whiteSpace: "nowrap" as "nowrap",
    },
    slide: {
        paddingLeft: 50,
        paddingRight: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    image: {
        height: "50px",
        marginRight: "10px",
    },
    text: {
        fontFamily: '"PP Mori", sans-serif',
        color: "white",
        backgroundColor: "black",
        padding: "10px 15px",
        borderRadius: "5px",
    },
};

export default MovingWidget;
